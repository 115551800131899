import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  IconButton,
  Slide,
  TextField,
  // Tooltip,
  Typography,
} from "@mui/material";
// import BugsIcon from "Assets/Images/bugs.webp";
import UploadIcon from "Assets/Images/UploadIcon.webp";
import ModalPopUp from "Components/ModalPopUp";
import MrButton from "Components/MrButton";
import { FileUploader } from "react-drag-drop-files";
import successGif from "Assets/Images/successGif.gif";
import CloseIcon from "@mui/icons-material/Close";

const fileTypes = ["JPG", "PNG", "GIF"];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Navbar = () => {
  const urlPath = window.location.pathname;
  const [reportPop, setReportPop] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    issue: "",
  });
  const [file, setFile] = useState(null);
  const [resetUploader, setResetUploader] = useState(false);
  const [responsePop, setResponsePop] = useState(false);

  const handleImg = (file) => {
    setFile(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    console.log(file, "file");

    setFormData({
      name: "",
      email: "",
      issue: "",
    });
    setFile(null);
    setResetUploader((prev) => !prev);
    setReportPop(false);
    setResponsePop(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "1.5rem",
          fontWeight: "600",
          height: "100%",
          px: "1.44rem",
          backgroundColor: "white.main",
        }}
      >
        <Box>
          <Typography fontWeight={600} variant="h5" sx={{ fontSize: "1.5rem" }}>
            Partner Dashboard&nbsp;|&nbsp;
            <Typography
              variant="caption"
              fontWeight={600}
              sx={{ fontSize: "1.5rem", color: "primary.main" }}
            >
              {urlPath?.includes("/analytics")
                ? "Analytics"
                : urlPath?.includes("/international-sim")
                ? "International SIM's"
                : urlPath?.includes("/orders")
                ? "Orders"
                : urlPath?.includes("/transictions")
                ? "Transactions"
                : urlPath?.includes("/mystock")
                ? "My Stock"
                : "Prune DCS"}
            </Typography>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          {/* <Box sx={{ display: "flex", flexDirection: "row", gap: ".5rem" }}>
            <AccountBalanceWalletIcon />
            <Typography
              variant="h5"
              fontWeight={500}
              sx={{ fontSize: "1.2rem" }}
            >
              &#8377; 1200.00
            </Typography>
          </Box> */}
          {/* <Box onClick={() => setReportPop(true)}>
            <IconButton>
              <Tooltip title={"Report a Problem"} sx={{ color: "red" }} arrow>
                <Box
                  sx={{
                    height: "1.2rem",
                    width: "1.2rem",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={BugsIcon}
                    alt="No Preview"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("Assets/Images/no-preview.jpeg");
                    }}
                  />
                </Box>
              </Tooltip>
            </IconButton>
          </Box> */}
        </Box>
      </Box>

      <ModalPopUp
        open={reportPop}
        onClose={() => setReportPop(false)}
        title="Report an Issue"
        width={"28rem"}
        height={"90%"}
        showHeader={true}
        justifyContent={"start"}
      >
        <Box
          sx={{
            p: "1rem",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <TextField
                onChange={handleChange}
                fullWidth
                required
                type="text"
                id="outlined-required"
                label="Name"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    width: "100%",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
                name="name"
                value={formData.name}
              />
              <TextField
                onChange={handleChange}
                fullWidth
                required
                type="email"
                id="outlined-required"
                label="Email ID"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    width: "100%",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
                name="email"
                value={formData.email}
              />

              <TextField
                onChange={handleChange}
                fullWidth
                required
                type="text"
                id="outlined-required"
                label="Your Issue"
                placeholder="Please explain Problem you are facing here"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    width: "100%",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
                name="issue"
                multiline
                minRows={3}
                maxRows={3}
                value={formData.issue}
              />

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Typography variant="body1">Add an Image</Typography>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      height: "3rem",
                      width: "3rem",
                      cursor: "pointer",
                      position: "absolute",
                      top: "25%",
                      left: "50%",
                      transform: "translate(-50%,0%)",
                    }}
                  >
                    <img
                      src={UploadIcon}
                      alt="No Preview"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("Assets/Images/no-preview.jpeg");
                      }}
                    />
                  </Box>
                  <FileUploader
                    key={resetUploader}
                    handleChange={handleImg}
                    name="file"
                    types={fileTypes}
                    value={file}
                    height={"7rem"}
                    label="Drag & drop file here or click to browse"
                    classes="drope_zone"
                    dropMessageStyle={{
                      backgroundColor: "#f589ff",
                    }}
                    required
                  />
                </Box>
                {file ? (
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "0.7rem", color: "#666666" }}
                  >
                    {file.name}
                  </Typography>
                ) : null}
              </Box>
              <MrButton type={"submit"} variant={"contained"} width={"100%"}>
                Submit
              </MrButton>
            </Box>
          </form>
        </Box>
      </ModalPopUp>

      {/* response successes pop up  */}

      <Dialog
        open={responsePop}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setResponsePop(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiDialog-paper": {
            borderRadius: ".75rem",
            overflow: "hidden",
            py: "1.5rem",
            px: { xs: "0rem", sm: "2rem", md: "2rem" },
            // border: "1px solid red",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "25rem",
            alignItems: "center",
            // border: "1px solid blue",
          }}
        >
          <Box sx={{ height: "7.5rem", width: "7.5rem" }}>
            <img
              src={successGif}
              alt="No Preview"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../Assets/Images/no-preview.jpeg");
              }}
            />
          </Box>
          <Typography
            variant="body1"
            sx={{
              mb: "0.5rem",
              textAlign: "center",
              fontWeight: "500",
              fontSize: "1.25rem",
              color: "black.main",
            }}
          >
            Report Submitted Succesfully
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography variant="body1" sx={{ fontSize: "0.875rem" }}>
              You can view status of your tickets in my tickets section
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                border: "1px solid #DADADA",
                borderRadius: "0.625rem",
                px: "1rem",
                py: "0.5rem",
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", fontWeight: "400", color: "#606060" }}
                >
                  Ticket ID
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  7847387468763
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", fontWeight: "400", color: "#606060" }}
                >
                  Date & Time
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  23-10-2022 at 14:00
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <DialogActions>
          <IconButton
            onClick={() => setResponsePop(false)}
            sx={{
              position: "absolute",
              top: ".5rem",
              right: ".5rem",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
