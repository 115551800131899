import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const token = localStorage.getItem("token");

export const getAllInternationalList = createAsyncThunk(
  "getAllInternationalList",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);
export const getTopupByCountry = createAsyncThunk(
  "getTopupByCountry",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getuserandPlanDetailsByEmnailId = createAsyncThunk(
  "getuserandPlanDetailsByEmnailId",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

const internationalSlice = createSlice({
  name: "internationalSlice",
  initialState: {
    loading: false,
    internationalList: null,
    topupLoading: false,
    allTopup: null,
    detailByEmailId: null,
    totalpage: null,
    totalPageOfEmail: null,
    currentPlan: null,
  },

  reducers: {
    getCurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllInternationalList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllInternationalList.fulfilled, (state, action) => {
        state.loading = false;
        state.internationalList = action?.payload;
        state.totalpage = action?.payload?.results?.total_pages;
      })
      .addCase(getAllInternationalList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTopupByCountry.pending, (state) => {
        state.topupLoading = true;
      })
      .addCase(getTopupByCountry.fulfilled, (state, action) => {
        state.topupLoading = false;
        state.allTopup = action?.payload;
      })
      .addCase(getTopupByCountry.rejected, (state) => {
        state.topupLoading = false;
      })
      .addCase(getuserandPlanDetailsByEmnailId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getuserandPlanDetailsByEmnailId.fulfilled, (state, action) => {
        state.loading = false;
        state.detailByEmailId = action?.payload;
        state.totalPageOfEmail = action?.payload?.total_pages;
      })
      .addCase(getuserandPlanDetailsByEmnailId.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { getCurrentPlan } = internationalSlice.actions;
export default internationalSlice.reducer;
