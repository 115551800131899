import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const token = localStorage.getItem("token");

export const getSingleEsimDetails = createAsyncThunk(
  "getSingleEsimDetails",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getSinglePhysicalDetails = createAsyncThunk(
  "getSinglePhysicalDetails",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getSingleRegionalDetails = createAsyncThunk(
  "getSingleRegionalDetails",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getSingleGlobalDetails = createAsyncThunk(
  "getSingleGlobalDetails",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

const plandetails = createSlice({
  name: "plandetails",
  initialState: {
    loading: false,
    esimPlanDetails: null,
    physicalPlanDetails: null,
    regionalPlanDetails: null,
    globalPlanDetails: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSingleEsimDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleEsimDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.esimPlanDetails = action?.payload;
      })
      .addCase(getSingleEsimDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSinglePhysicalDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSinglePhysicalDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.physicalPlanDetails = action?.payload;
      })
      .addCase(getSinglePhysicalDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSingleRegionalDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleRegionalDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.regionalPlanDetails = action?.payload;
      })
      .addCase(getSingleRegionalDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSingleGlobalDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleGlobalDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.globalPlanDetails = action?.payload;
      })
      .addCase(getSingleGlobalDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default plandetails.reducer;
