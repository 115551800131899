import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const token = localStorage.getItem("token");

export const getAllTransactions = createAsyncThunk(
  "getAllTransactions",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

const transactionSlice = createSlice({
  name: "transactionSlice",
  initialState: {
    loader: false,
    allTransactions: null,
    // totalPage: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllTransactions.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        state.loader = false;
        state.allTransactions = action?.payload;
        // state.totalPage = action?.payload?.results?.total_pages;
      })
      .addCase(getAllTransactions.rejected, (state) => {
        state.loader = false;
      });
  },
});

export default transactionSlice.reducer;
