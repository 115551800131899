import analiyze from "Assets/Images/analyze.webp";
import esim from "Assets/Images/esim.webp";
import order from "Assets/Images/order.webp";
import transitions from "Assets/Images/transitions.webp";
// import myStocIcon from "Assets/Images/mystockIcon.webp";
import revenue from "Assets/Images/revenue.webp";
import totaleSIMsold from "Assets/Images/totaleSIMsold.webp";
import eSIMSold from "Assets/Images/eSIMSold.webp";
import countriesReached from "Assets/Images/countriesReached.webp";
import India from "Assets/Images/India.webp";
import Australia from "Assets/Images/Australia.webp";
import Brazil from "Assets/Images/Brazil.webp";
import Russia from "Assets/Images/Russia.webp";
import USA from "Assets/Images/USA.webp";
import shareIcon from "Assets/Images/shareIcon.webp";
import uploadPopIcon from "Assets/Images/uploadPop.webp";
import cart from "Assets/Images/cart.webp";

// --------------------------------- Non Delete Dummy Data ----------------------------------------------
export const sideNavMenus = [
  { menuItem: "Sim Order", path: "/", icon: cart },
  { menuItem: "Analytics", path: "/analytics", icon: analiyze },
  { menuItem: "Top-up SIM", path: "/international-sim", icon: esim },
  { menuItem: "Orders", path: "/orders", icon: order },
  { menuItem: "Transactions", path: "/transaction", icon: transitions },
  // { menuItem: "My Stock", path: "/mystock", icon: myStocIcon },
];

export const analyticsData = [
  {
    image: revenue,
    numberData: 4500,
    title: "Revenue",
  },
  {
    image: totaleSIMsold,
    numberData: 45000,
    title: "eSIM’s sold",
  },
  {
    image: eSIMSold,
    numberData: 215,
    title: "eSIM’s sold",
  },
  {
    image: countriesReached,
    numberData: 15,
    title: "Countries Reached",
  },
];

export const esimeTableData = [
  {
    countryName: "India",
    countryFlag: India,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Recharge",
    status: "Delivering",
  },
  {
    countryName: "Brazil",
    countryFlag: Brazil,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Purchase",
    status: "Activated",
  },
  {
    countryName: "India",
    countryFlag: India,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Recharge",
    status: "Activation Pending",
  },
  {
    countryName: "Australia",
    countryFlag: Australia,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Recharge",
    status: "Activated",
  },
  {
    countryName: "United States",
    countryFlag: USA,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Purchase",
    status: "Activation Pending",
  },
  {
    countryName: "United States",
    countryFlag: USA,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Recharge",
    status: "Delivering",
  },
  {
    countryName: "Russia",
    countryFlag: Russia,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Purchase",
    status: "Delivering",
  },
  {
    countryName: "India",
    countryFlag: India,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Recharge",
    status: "Activation Pending",
  },
  {
    countryName: "India",
    countryFlag: India,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Purchase",
    status: "Delivering",
  },
  {
    countryName: "United States",
    countryFlag: USA,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Recharge",
    status: "Delivering",
  },
  {
    countryName: "India",
    countryFlag: India,
    iccId: "884579612365548411",
    issueAt: "21/05/2024 5:13 GMT +5:30",
    assignedTo: "Sajan",
    uploadIcon: uploadPopIcon,
    shareIcon: shareIcon,
    type: "Recharge",
    status: "Activated",
  },
];

// ------------------------------------- Delete Dummy Data -------------------------------------------------

export const countryData = [
  {
    image: India,
    countryName: "India",
    countryData: 50,
  },
  {
    image: USA,
    countryName: "USA",
    countryData: 25,
  },
  {
    image: Russia,
    countryName: "Russia",
    countryData: 60,
  },
  {
    image: Australia,
    countryName: "Australia",
    countryData: 70,
  },
  {
    image: Brazil,
    countryName: "Brazil",
    countryData: 95,
  },
];

export const eSimUsageData = [
  {
    totalData: 2048,
    leftData: 1024,
    type: "MB",
    CallTextData: [
      { totalData: 50, leftData: 50, type: "Voicecall" },
      { leftData: 25, totalData: 50, type: "textsms" },
    ],
  },
  {
    totalData: 60,
    leftData: 50,
    type: "days",
  },
];

export const orderTableData = [
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "eSIM purchase",
    totalPrice: "1338",
    status: "pending",
  },
  {
    orderId: "20222444184-13157",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "eSIM Top up",
    totalPrice: "1338",
    status: "completed",
  },
  {
    orderId: "20222444184-13158",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "Physical Int’L SIM purchase",
    totalPrice: "1338",
    status: "reupload",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "eSIM purchase",
    totalPrice: "1338",
    status: "pending",
  },

  {
    orderId: "20222444184-13157",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "eSIM Top up",
    totalPrice: "1338",
    status: "completed",
  },
  {
    orderId: "20222444184-13158",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "Physical Int’L SIM purchase",
    totalPrice: "1338",
    status: "reupload",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "eSIM purchase",
    totalPrice: "1338",
    status: "pending",
  },
  {
    orderId: "20222444184-13157",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "eSIM Top up",
    totalPrice: "1338",
    status: "completed",
  },
  {
    orderId: "20222444184-13158",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "Physical Int’L SIM purchase",
    totalPrice: "1338",
    status: "reupload",
  },
  {
    orderId: "20222444184-13158",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    type: "Physical Int’L SIM purchase",
    totalPrice: "1338",
    status: "reupload",
  },
];

export const topUpCardData = [
  { data: 1, validity: 7, price: 420 },
  { data: 2, validity: 15, price: 630 },
  { data: 3, validity: 30, price: 840 },
  { data: 5, validity: 30, price: 1260 },
  { data: 10, validity: 30, price: 1890 },
  { data: 20, validity: 30, price: 3024 },
  { data: 20, validity: 30, price: 3024 },
  { data: 20, validity: 30, price: 3024 },
  { data: 20, validity: 30, price: 3024 },
  { data: 20, validity: 30, price: 3024 },
  { data: 20, validity: 30, price: 3024 },
  { data: 20, validity: 30, price: 3024 },
  { data: 20, validity: 30, price: 3024 },
  { data: 20, validity: 30, price: 3024 },
];

export const transactionTableData = [
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "Mrityunjay Kumar",
    category: "eSIM order",
    totalPrice: "1338",
    paymentStatus: "credit",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "Pralay Kumar",
    category: "refund",
    totalPrice: "2500",
    paymentStatus: "credit",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "Abhay Kumar",
    category: "eSIM order",
    totalPrice: "1338",
    paymentStatus: "credit",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "Nagesh Kumar Pandey",
    category: "refund",
    totalPrice: "18000",
    paymentStatus: "credit",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "bob kumar",
    category: "refund",
    totalPrice: "50000",
    paymentStatus: "debit",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "Mrityunjay Kumar",
    category: "eSIM order",
    totalPrice: "1338",
    paymentStatus: "debit",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "Pralay Kumar",
    category: "refund",
    totalPrice: "2500",
    paymentStatus: "credit",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "Abhay Kumar",
    category: "eSIM order",
    totalPrice: "1338",
    paymentStatus: "debit",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "Nagesh Kumar Pandey",
    category: "refund",
    totalPrice: "18000",
    paymentStatus: "debit",
  },
  {
    orderId: "20222444184-13156",
    dateAndTime: "21/05/2024 5:13 GMT +5:30",
    userName: "bob kumar",
    category: "refund",
    totalPrice: "50000",
    paymentStatus: "debit",
  },
];

export const countryCardData = [
  {
    id: 1,
    image: India,
    countryName: "India",
  },
  {
    id: 2,
    image: Australia,
    countryName: "united kingdom",
  },
  {
    id: 3,
    image: Brazil,
    countryName: "Brazil",
  },
  {
    id: 4,
    image: Russia,
    countryName: "Russia",
  },
  {
    id: 5,
    image: USA,
    countryName: "USA",
  },
  {
    id: 6,
    image: India,
    countryName: "India",
  },
  {
    id: 7,
    image: Australia,
    countryName: "Australia",
  },
  {
    id: 8,
    image: Brazil,
    countryName: "Brazil",
  },
  {
    id: 9,
    image: Russia,
    countryName: "Russia",
  },
  {
    id: 10,
    image: USA,
    countryName: "USA",
  },
  {
    id: 11,
    image: India,
    countryName: "India",
  },
  {
    id: 12,
    image: Australia,
    countryName: "united kingdom",
  },
  {
    id: 13,
    image: Brazil,
    countryName: "Brazil",
  },
  {
    id: 14,
    image: Russia,
    countryName: "Russia",
  },
  {
    id: 15,
    image: USA,
    countryName: "USA",
  },
  {
    id: 16,
    image: India,
    countryName: "India",
  },
  {
    id: 17,
    image: Australia,
    countryName: "Australia",
  },
  {
    id: 18,
    image: Brazil,
    countryName: "Brazil",
  },
  {
    id: 19,
    image: Russia,
    countryName: "Russia",
  },
  {
    id: 20,
    image: USA,
    countryName: "USA",
  },
  {
    id: 21,
    image: India,
    countryName: "India",
  },
  {
    id: 22,
    image: Australia,
    countryName: "united kingdom",
  },
  {
    id: 23,
    image: Brazil,
    countryName: "Brazil",
  },
  {
    id: 24,
    image: Russia,
    countryName: "Russia",
  },
  {
    id: 25,
    image: USA,
    countryName: "USA",
  },
  {
    id: 26,
    image: India,
    countryName: "India",
  },
  {
    id: 27,
    image: Australia,
    countryName: "Australia",
  },
  {
    id: 28,
    image: Brazil,
    countryName: "Brazil",
  },
  {
    id: 29,
    image: Russia,
    countryName: "Russia",
  },
  {
    id: 30,
    image: USA,
    countryName: "USA",
  },
  {
    id: 31,
    image: India,
    countryName: "India",
  },
  {
    id: 32,
    image: Australia,
    countryName: "united kingdom",
  },
  {
    id: 33,
    image: Brazil,
    countryName: "Brazil",
  },
  {
    id: 34,
    image: Russia,
    countryName: "Russia",
  },
  {
    id: 35,
    image: USA,
    countryName: "USA",
  },
  {
    id: 36,
    image: India,
    countryName: "India",
  },
  {
    id: 37,
    image: Australia,
    countryName: "Australia",
  },
  {
    id: 38,
    image: Brazil,
    countryName: "Brazil",
  },
  {
    id: 39,
    image: Russia,
    countryName: "Russia",
  },
  {
    id: 40,
    image: USA,
    countryName: "USA",
  },
];

export const regionData = [
  {
    id: 1,
    regionalName: "Asia",
    image:
      "https://w7.pngwing.com/pngs/994/390/png-transparent-asia-pacific-southeast-asia-world-middle-east-map-monochrome-computer-wallpaper-world-thumbnail.png",
  },
  {
    id: 2,
    regionalName: "Africa",
    image:
      "https://w7.pngwing.com/pngs/994/390/png-transparent-asia-pacific-southeast-asia-world-middle-east-map-monochrome-computer-wallpaper-world-thumbnail.png",
  },
  {
    id: 3,
    regionalName: "Europe",
    image:
      "https://w7.pngwing.com/pngs/994/390/png-transparent-asia-pacific-southeast-asia-world-middle-east-map-monochrome-computer-wallpaper-world-thumbnail.png",
  },
  {
    id: 4,
    regionalName: "Middle East & North Africa",
    image:
      "https://w7.pngwing.com/pngs/994/390/png-transparent-asia-pacific-southeast-asia-world-middle-east-map-monochrome-computer-wallpaper-world-thumbnail.png",
  },
  {
    id: 5,
    regionalName: "Latin America",
    image:
      "https://w7.pngwing.com/pngs/994/390/png-transparent-asia-pacific-southeast-asia-world-middle-east-map-monochrome-computer-wallpaper-world-thumbnail.png",
  },
  {
    id: 6,
    regionalName: "Caribbean Island",
    image:
      "https://w7.pngwing.com/pngs/994/390/png-transparent-asia-pacific-southeast-asia-world-middle-east-map-monochrome-computer-wallpaper-world-thumbnail.png",
  },
  {
    id: 7,
    regionalName: "North America",
    image:
      "https://w7.pngwing.com/pngs/994/390/png-transparent-asia-pacific-southeast-asia-world-middle-east-map-monochrome-computer-wallpaper-world-thumbnail.png",
  },
];

export const countryPlanDataIndia = [
  {
    opratorName: "Reliance Jio",
    countryName: "Japan",
    data: "1 GB",
    validity: "7 Days",
    coverage: "Japan",
    planPrice: 1322,
    network: "NTT Docomo",
    activationPolicy:
      "The validity period starts when the eSIM contects to any supported network/s.",
    onlineVerification: true,
    topUpOption: "Available",
    planType: "Data",
  },
  {
    opratorName: "Reliance Jio",
    countryName: "Japan",
    data: "5 GB",
    validity: "13 Days",
    coverage: "Japan",
    planPrice: 2489,
    network: "NTT Idia",
    activationPolicy:
      "The validity period starts when the eSIM contects to any supported network/s.",
    onlineVerification: false,
    topUpOption: "Available",
    planType: "Balance",
  },
  {
    opratorName: "Reliance Jio",
    countryName: "Japan",
    data: "1 GB",
    validity: "5 Days",
    coverage: "Japan",
    planPrice: 500,
    network: "NTT Jio",
    activationPolicy:
      "The validity period starts when the eSIM contects to any supported network/s.",
    onlineVerification: true,
    topUpOption: "Available",
    planType: "Data",
  },
  {
    opratorName: "Reliance Jio",
    countryName: "Japan",
    data: "1.5 GB",
    validity: "13 Days",
    coverage: "Japan",
    planPrice: 2489,
    network: "NTT Airtel",
    activationPolicy:
      "The validity period starts when the eSIM contects to any supported network/s.",
    onlineVerification: true,
    topUpOption: "Available",
    planType: "Balance",
  },
  {
    opratorName: "Reliance Jio",
    countryName: "Japan",
    data: "1.5 GB",
    validity: "13 Days",
    coverage: "Japan",
    planPrice: 2489,
    network: "NTT Airtel",
    activationPolicy:
      "The validity period starts when the eSIM contects to any supported network/s.",
    onlineVerification: true,
    topUpOption: "Available",
    planType: "Balance",
  },
];

export const regionalPlanData = [
  {
    provider: "Sonam Gupta",
    totalCountry: "15 Country",
    data: "4 GB",
    validity: "30 Days",
    planPrice: 1252,
  },
  {
    provider: "Sonam Gupta",
    totalCountry: "15 Country",
    data: "23 GB",
    validity: "87 Days",
    planPrice: 5440,
  },
  {
    provider: "Sonam Gupta",
    totalCountry: "15 Country",
    data: "4GB",
    validity: "50 Days",
    planPrice: 987,
  },
  {
    provider: "Sonam Gupta",
    totalCountry: "15 Country",
    data: "4GB",
    validity: "23 Days",
    planPrice: 548,
  },
  {
    provider: "Sonam Gupta",
    totalCountry: "15 Country",
    data: "4GB",
    validity: "35 Days",
    planPrice: 244,
  },
];

export const simOpratorCardData = [
  {
    operator: "airtel",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 1252,
  },
  {
    operator: "bsnl",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 682,
  },
  {
    operator: "jio",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 688,
  },
  {
    operator: "vi",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 345,
  },
  {
    operator: "jio",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 688,
  },
  {
    operator: "vi",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 345,
  },
  {
    operator: "vi",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 345,
  },
  {
    operator: "jio",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 688,
  },
  {
    operator: "vi",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 345,
  },
  {
    operator: "airtel",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 1252,
  },
  {
    operator: "bsnl",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 682,
  },
  {
    operator: "jio",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 688,
  },
  {
    operator: "vi",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 345,
  },
  {
    operator: "jio",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 688,
  },
  {
    operator: "vi",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 345,
  },
  {
    operator: "vi",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 345,
  },
  {
    operator: "jio",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 688,
  },
  {
    operator: "vi",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 345,
  },
  {
    operator: "jio",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 688,
  },
  {
    operator: "vi",
    validity: "30 Days",
    data: "1GB /Day",
    calling: "unlimited",
    sms: "100sms/day",
    planPrice: 345,
  },
];
