import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const token = localStorage.getItem("token");

export const getAllOrder = createAsyncThunk("getAllOrder", async ({ url }) => {
  try {
    const response = await axios.get(url, {
      headers: {
        authorization: `Token ${token}`,
        "Content-Type": "application/json",
        // authorization: `Basic ${basicAuth}`,
        // "Access-Control-Allow-Origin": "*",
      },
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

export const getSingleOrder = createAsyncThunk(
  "getSingleOrder",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

const orderSlice = createSlice({
  name: "orderSlice",
  initialState: {
    loading: false,
    allOrders: null,
    totalPage: null,
    singleOrder: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.allOrders = action?.payload;
        state.totalPage = action?.payload?.results?.total_pages;
      })
      .addCase(getAllOrder.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSingleOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.singleOrder = action?.payload;
      })
      .addCase(getSingleOrder.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default orderSlice.reducer;
