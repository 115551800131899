import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const token = localStorage?.getItem("token");

export const getAllCountries = createAsyncThunk(
  "getAllCountries",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          // "Content-Type": "application/json",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

// export const getAllCountries = createAsyncThunk(
//   "getAllCountries",
//   async ({ url }) => {
//     try {
//       // Wait for the token to be available
//       const token = localStorage.getItem("token");

//       // Check if token exists
//       if (!token) {
//         throw new Error("Token not found, unauthorized access");
//       }

//       // Make the request with the token
//       const response = await axios.get(url, {
//         headers: {
//           authorization: `Token ${token}`,
//         },
//       });

//       return response?.data;
//     } catch (error) {
//       console.error("Error fetching countries:", error);
//       // Handle the error based on the presence of the token or other issues
//       return error?.response?.data || { message: "Unauthorized or other error" };
//     }
//   }
// );

export const getSingleplans = createAsyncThunk(
  "getSingleplans",
  async ({ url, data }) => {
    try {
      const response = await axios.get(url, data, {
        headers: {
          authorization: `Token ${token}`,
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const convertPriceIntoIndian = createAsyncThunk(
  "convertPriceIntoIndian",
  async ({ url, data }) => {
    try {
      const response = await axios.get(url, data, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getAllPhysicalSimByCountryCode = createAsyncThunk(
  "getAllPhysicalSimByCountryCode",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getAllRegion = createAsyncThunk(
  "getAllRegion",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getAllPlansByRegionName = createAsyncThunk(
  "getAllPlansByRegionName",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getAllGlobalPlan = createAsyncThunk(
  "getAllGlobalPlan",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

const homeSlice = createSlice({
  name: "homeSlice",
  initialState: {
    loading: false,
    homeData: null,
    singleCountryPlans: null,
    allIndianPrice: null,
    allPhysicalSim: null,
    allRegion: null,
    allPlansByRegion: null,
    allGlobalPlan: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.homeData = action?.payload;
      })
      .addCase(getAllCountries.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSingleplans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleplans.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCountryPlans = action?.payload;
      })
      .addCase(getSingleplans.rejected, (state) => {
        state.loading = false;
      })
      .addCase(convertPriceIntoIndian.fulfilled, (state, action) => {
        state.allIndianPrice = action?.payload?.inr_prices;
      })
      .addCase(getAllPhysicalSimByCountryCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPhysicalSimByCountryCode.fulfilled, (state, action) => {
        state.loading = false;
        state.allPhysicalSim = action?.payload?.sim_packages_info;
      })
      .addCase(getAllPhysicalSimByCountryCode.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllRegion.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRegion.fulfilled, (state, action) => {
        state.loading = false;
        state.allRegion = action?.payload;
      })
      .addCase(getAllRegion.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllPlansByRegionName.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlansByRegionName.fulfilled, (state, action) => {
        state.loading = false;
        state.allPlansByRegion = action?.payload;
      })
      .addCase(getAllPlansByRegionName.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllGlobalPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllGlobalPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.allGlobalPlan = action?.payload;
      })
      .addCase(getAllGlobalPlan.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default homeSlice.reducer;
