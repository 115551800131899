import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const token = localStorage.getItem("token");

export const uploadDocuments = createAsyncThunk(
  "uploadDocuments",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: `Token ${token}`,
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const documentUpload = createSlice({
  name: "documentUpload",
  initialState: {
    loading: false,
  },

  reducers: {},
});

export default documentUpload.reducer;
