import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  TextField,
} from "@mui/material";
import LoginPageImage from "../../Components/LoginPageImage/index";
import LoginPageHeding from "../../Components/LoginHeading/index";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import MrButton from "Components/MrButton";
import { userLogin } from "../../Redux/Features/UserAuthSlice";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { getFirstCharacters } from "Utils";

const ResetPassLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userEmail = localStorage.getItem("email");
  const userName = localStorage.getItem("userName");

  const [formData, setFormData] = useState({ password: "", repassword: "" });
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "repassword") {
      setShowError(value !== formData.password);
    } else if (name === "password") {
      setShowError(formData.repassword !== "" && value !== formData.repassword);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const { password, repassword } = formData;

    dispatch(
      userLogin({
        url: `${process.env.REACT_APP_URI}reseller/login/`,
        data: {
          step: "change_password",
          email: userEmail,
          new_password: repassword,
        },
      })
    ).then((res) => {
      setLoading(false);
      if (
        res?.payload?.message === "Password changed successfully." &&
        password === repassword
      ) {
        toast.success("Password successfully updated");
        navigate("/");
        localStorage.setItem("status", res?.payload?.status);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box sx={{ display: { xs: "none", sm: "block" }, width: "50%" }}>
          <LoginPageImage flexDirection={"column-reverse"} />
        </Box>
        <Box
          sx={{
            height: "100%",
            width: {
              xs: "100%",
              sm: "50%",
              md: "50%",
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              px: { xs: "1rem", sm: "8.8rem", md: "8.8rem" },
            }}
          >
            <LoginPageHeding />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mt: "1rem",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "#00C471",
                  height: "3rem",
                  width: "3rem",
                  mr: ".75rem",
                }}
              >
                {getFirstCharacters(userName)}
              </Avatar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                  mb: "1rem",
                }}
              >
                <Typography variant="subtitle2">{userName || "-"}</Typography>
                <Typography variant="subtitle1" sx={{ fontSize: "0.75rem" }}>
                  Prune
                </Typography>
              </Box>
            </Box>

            <form style={{ width: "100%" }} action="#" onSubmit={handleSubmit}>
              <Typography
                fontWeight={500}
                variant="subtitle2"
                sx={{ fontSize: "1rem" }}
              >
                Change your Password
              </Typography>
              <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                <FormControl sx={{ width: "100%" }}>
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    sx={{ fontSize: "1rem", mb: ".5rem" }}
                  >
                    Enter new password here
                  </Typography>
                  <TextField
                    required
                    type="text"
                    size="small"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "100%",
                        height: "3rem",
                        borderRadius: "0.625rem",
                      },
                    }}
                  />
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    sx={{ fontSize: "1rem", mb: ".5rem" }}
                  >
                    Re-enter password to verify
                  </Typography>
                  <OutlinedInput
                    sx={{
                      height: "3rem",
                      borderRadius: "0.625rem",
                    }}
                    required
                    value={formData.repassword}
                    name="repassword"
                    onChange={handleChange}
                    id="outlined-adornment-password"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {showError && (
                    <Typography variant="body2" color="error" mt=".3rem">
                      The entered password does not match
                    </Typography>
                  )}
                </FormControl>
              </Box>

              {loading ? (
                <LoadingButton
                  sx={{
                    mt: 3,
                    height: "3rem",
                    borderRadius: "0.625rem",
                    fontSize: "3rem",
                    width: "100%",
                    "&.MuiLoadingButton-icon": {
                      fontSize: "3rem",
                    },
                  }}
                  color="secondary"
                  loading={loading}
                  variant="contained"
                ></LoadingButton>
              ) : (
                <Box mt={2}>
                  <MrButton
                    type={"submit"}
                    fontSize={"1rem"}
                    width={"100%"}
                    textTransform={"capitalize"}
                    height={"3rem"}
                    borderRadius={"0.625rem"}
                    fontWeight={"300"}
                    variant={"contained"}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Update Password
                  </MrButton>
                </Box>
              )}
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassLogin;
