import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import verified_vector from "Assets/Images/verified_vector.jpg";
import CircleIcon from "@mui/icons-material/Circle";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import passFront from "Assets/Images/passFront.png";
import passBack from "Assets/Images/passBack.png";
import PropTypes from "prop-types";
import visaimg from "Assets/Images/visaimg.png";
import ticketimg from "Assets/Images/ticketimg.png";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { uploadDocuments } from "../../Redux/Features/documentUpload";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const DucumentUpload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order_id = localStorage.getItem("order_id");
  const [step, setStep] = useState(1);
  const [passbookFront, setPassbookFront] = useState(null);
  const [passbookBack, setPassbookBack] = useState(null);
  const [visa, setVisa] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [btnloading, setBtnLoading] = useState(false);

  console.log(passbookFront, passbookBack, visa, ticket, "Passbook NAME");

  const handleFileChange = (event, setImage) => {
    const file = event?.target?.files[0];
    if (file) {
      setImage(file); // Store the actual file in state
    }
  };

  // const handleFileChange = (event, setImage) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setImage(URL.createObjectURL(file)); // Create and store the file's URL
  //   }
  // };

  const UploadButton = ({ setImage, label, varient }) => (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Button
        variant={varient ? varient : "contained"}
        component="label" // Attach the file input directly to the button
        sx={{
          height: "3.75rem",
          borderRadius: ".625rem",
          width: "16rem",
          px: "2rem",
          textTransform: "capitalize",
          fontWeight: 400,
          fontSize: "1rem",
        }}
      >
        {label}
        <input
          type="file"
          onChange={(event) => handleFileChange(event, setImage)}
          style={{ display: "none" }}
        />
      </Button>
    </Box>
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnLoading(true);

    const formsData = new FormData();
    formsData.append("upload_data", "");
    formsData.append("esim_id", order_id);
    formsData.append("docfile", passbookFront); // Upload the actual file
    formsData.append("docfile_visa", visa);
    formsData.append("docfile_ticket", ticket);
    formsData.append("docfile_back", passbookBack);

    dispatch(
      uploadDocuments({
        url: `${process.env.REACT_APP_DOC}esim/single`,
        data: formsData,
      })
    ).then((res) => {
      setBtnLoading(false);
      if (res?.payload) {
        toast.success("Your order has been successfully placed");
        navigate("/");
        // navigate("/d-kyc");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            height: "100%",
            borderRadius: "0.9375rem",
            border: "1px solid #DADADA",
            p: "1.5rem",
          }}
        >
          {step === 1 ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box sx={{ height: "4.2rem", width: "4rem" }}>
                  <img
                    src={verified_vector}
                    alt="No Preview"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      mixBlendMode: "multiply",
                    }}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("Assets/Images/no-preview.jpeg");
                    }}
                  />
                </Box>

                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ fontSize: "1.5rem", color: "#1C1B1F" }}
                >
                  Start Manual Verification
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  sx={{ fontSize: ".875rem", color: "#606060" }}
                >
                  Our identity verification process is designed to protect you
                  from identity theft and ensure your security.
                </Typography>
              </Box>

              <Typography
                variant="h5"
                fontWeight={400}
                sx={{
                  fontSize: "1rem",
                  color: "#1C1B1F",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mt: "2rem",
                  gap: "1rem",
                }}
              >
                <CircleIcon sx={{ color: "black.main", fontSize: ".7rem" }} />
                Documents Required for Manual Verification:
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".2rem",
                  mt: ".5rem",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={400}
                  sx={{ fontSize: "1rem", color: "#1C1B1F" }}
                >
                  1. Passport
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={400}
                  sx={{ fontSize: "1rem", color: "#1C1B1F" }}
                >
                  2. VISA
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={400}
                  sx={{ fontSize: "1rem", color: "#1C1B1F" }}
                >
                  3. Travel Ticket
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: ".7rem",
                  alignItems: "center",
                  mt: "1.5rem",
                }}
              >
                <InfoIcon sx={{ fontSize: "1rem", color: "#c25729" }} />
                <Typography
                  variant="body1"
                  fontWeight={400}
                  sx={{ fontSize: ".875rem", color: "#1C1B1F" }}
                >
                  Verification page will expire in 10-15 mins, Please make sure
                  you have all the documents ready.
                </Typography>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "1.5rem" }}
              >
                <Button
                  onClick={() => setStep(2)}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    height: "3.75rem",
                    borderRadius: ".625rem",
                    px: "2rem",
                    textTransform: "capitalize",
                    fontWeight: 400,
                    fontSize: "1rem",
                  }}
                >
                  Start Manual Verification
                </Button>
              </Box>
            </Box>
          ) : step === 2 ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box sx={{ height: "4.2rem", width: "4rem" }}>
                  <img
                    src={verified_vector}
                    alt="No Preview"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      mixBlendMode: "multiply",
                    }}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("Assets/Images/no-preview.jpeg");
                    }}
                  />
                </Box>

                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ fontSize: "1.5rem", color: "#1C1B1F" }}
                >
                  Manual Verification
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  mt: "1rem",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    fontSize: "1.25rem",
                    color: "primary.main",
                    textAlign: "center",
                  }}
                >
                  Step 1: Passport Verification
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    fontSize: "1rem",
                    color: "black.main",
                    textAlign: "center",
                  }}
                >
                  Step 1.1: Passport Verification Front Side (Picture side)
                </Typography>

                {passbookFront ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography
                      variant="h3"
                      fontWeight={500}
                      sx={{
                        fontSize: "1.4rem",
                        color: "black.main",
                        textAlign: "center",
                      }}
                    >
                      Check Photo
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight={400}
                      sx={{
                        fontSize: "1rem",
                        color: "success.main",
                        textAlign: "center",
                      }}
                    >
                      Make sure Picture is clear and all Details on documents
                      are visible
                    </Typography>
                  </Box>
                ) : null}

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      height: "11.3rem",
                      width: "15.6rem",
                      border: "2px solid #e1e3e0",
                      borderRadius: "1.2rem",
                      overflow: "hidden",
                    }}
                  >
                    {passbookFront ? (
                      <img
                        src={URL.createObjectURL(passbookFront)} // Use the file URL
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    ) : (
                      <img
                        src={passFront} // Display the placeholder image if no file is uploaded
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    )}
                  </Box>
                </Box>

                {passbookFront ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "4rem",
                    }}
                  >
                    <UploadButton
                      setImage={setPassbookFront}
                      label="Try Again"
                      varient="outlined"
                    />

                    <Button
                      endIcon={<ArrowForwardIcon />}
                      variant="contained"
                      onClick={() => setStep(3)}
                      sx={{
                        height: "3.75rem",
                        width: "16rem",
                        borderRadius: ".625rem",
                        px: "2rem",
                        textTransform: "capitalize",
                        fontWeight: 400,
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                ) : (
                  <UploadButton
                    setImage={setPassbookFront}
                    label="Upload From Storage"
                    varient="contained"
                  />
                )}
              </Box>
            </Box>
          ) : step === 3 && passbookFront ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box sx={{ height: "4.2rem", width: "4rem" }}>
                  <img
                    src={verified_vector}
                    alt="No Preview"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      mixBlendMode: "multiply",
                    }}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("Assets/Images/no-preview.jpeg");
                    }}
                  />
                </Box>

                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ fontSize: "1.5rem", color: "#1C1B1F" }}
                >
                  Manual Verification
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  mt: "1rem",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    fontSize: "1.25rem",
                    color: "primary.main",
                    textAlign: "center",
                  }}
                >
                  Step 1: Passport Verification
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    fontSize: "1rem",
                    color: "black.main",
                    textAlign: "center",
                  }}
                >
                  Step 1.2: Passport Verification Back Side
                </Typography>

                {passbookBack ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography
                      variant="h3"
                      fontWeight={500}
                      sx={{
                        fontSize: "1.4rem",
                        color: "black.main",
                        textAlign: "center",
                      }}
                    >
                      Check Photo
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight={400}
                      sx={{
                        fontSize: "1rem",
                        color: "success.main",
                        textAlign: "center",
                      }}
                    >
                      Make sure Picture is clear and all Details on documents
                      are visible
                    </Typography>
                  </Box>
                ) : null}

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      height: "11.3rem",
                      width: "15.6rem",
                      border: "2px solid #e1e3e0",
                      borderRadius: "1.2rem",
                      overflow: "hidden",
                    }}
                  >
                    {passbookBack ? (
                      <img
                        src={URL.createObjectURL(passbookBack)} // Use the file URL
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    ) : (
                      <img
                        src={passBack}
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    )}
                  </Box>
                </Box>

                {passbookBack ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "4rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <UploadButton
                        setImage={setPassbookBack}
                        label="Try Again"
                        varient="outlined"
                      />
                    </Box>

                    <Button
                      endIcon={<ArrowForwardIcon />}
                      variant="contained"
                      onClick={() => setStep(4)}
                      sx={{
                        height: "3.75rem",
                        width: "16rem",
                        borderRadius: ".625rem",
                        px: "2rem",
                        textTransform: "capitalize",
                        fontWeight: 400,
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <UploadButton
                      setImage={setPassbookBack}
                      label="Upload From Storage"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ) : step === 4 && passbookFront && passbookBack ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box sx={{ height: "4.2rem", width: "4rem" }}>
                  <img
                    src={verified_vector}
                    alt="No Preview"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      mixBlendMode: "multiply",
                    }}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("Assets/Images/no-preview.jpeg");
                    }}
                  />
                </Box>

                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ fontSize: "1.5rem", color: "#1C1B1F" }}
                >
                  Manual Verification
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  mt: "1rem",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    fontSize: "1.25rem",
                    color: "primary.main",
                    textAlign: "center",
                  }}
                >
                  Step 2: Upload VISA Picture
                </Typography>
                {/* <Typography
                variant="h4"
                fontWeight={500}
                sx={{
                  fontSize: "1rem",
                  color: "black.main",
                  textAlign: "center",
                }}
              >
                Step 1.2: Passport Verification Back Side
              </Typography> */}

                {visa ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography
                      variant="h3"
                      fontWeight={500}
                      sx={{
                        fontSize: "1.4rem",
                        color: "black.main",
                        textAlign: "center",
                      }}
                    >
                      Check Photo
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight={400}
                      sx={{
                        fontSize: "1rem",
                        color: "success.main",
                        textAlign: "center",
                      }}
                    >
                      Make sure Picture is clear and all Details on documents
                      are visible
                    </Typography>
                  </Box>
                ) : null}

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      height: "11.3rem",
                      width: "15.6rem",
                      border: "2px solid #e1e3e0",
                      borderRadius: "1.2rem",
                      overflow: "hidden",
                    }}
                  >
                    {visa ? (
                      <img
                        src={URL.createObjectURL(visa)} // Use the file URL
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    ) : (
                      <img
                        src={visaimg}
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    )}
                  </Box>
                </Box>

                {visa ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "4rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <UploadButton
                        setImage={setVisa}
                        label="Try Again"
                        varient="outlined"
                      />
                    </Box>

                    <Button
                      endIcon={<ArrowForwardIcon />}
                      variant="contained"
                      onClick={() => setStep(5)}
                      sx={{
                        height: "3.75rem",
                        width: "16rem",
                        borderRadius: ".625rem",
                        px: "2rem",
                        textTransform: "capitalize",
                        fontWeight: 400,
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "4rem",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: ".625rem",
                        px: "2rem",
                        textTransform: "capitalize",
                        fontSize: "1rem",
                      }}
                      onClick={() => setStep(5)}
                    >
                      Skip
                    </Button>
                    <UploadButton
                      setImage={setVisa}
                      label="Upload From Storage"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ) : step === 5 ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box sx={{ height: "4.2rem", width: "4rem" }}>
                  <img
                    src={verified_vector}
                    alt="No Preview"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      mixBlendMode: "multiply",
                    }}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("Assets/Images/no-preview.jpeg");
                    }}
                  />
                </Box>

                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{ fontSize: "1.5rem", color: "#1C1B1F" }}
                >
                  Manual Verification
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  mt: "1rem",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    fontSize: "1.25rem",
                    color: "primary.main",
                    textAlign: "center",
                  }}
                >
                  Step 3: Upload your Ticket
                </Typography>

                {ticket ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography
                      variant="h3"
                      fontWeight={500}
                      sx={{
                        fontSize: "1.4rem",
                        color: "black.main",
                        textAlign: "center",
                      }}
                    >
                      Check Photo
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight={400}
                      sx={{
                        fontSize: "1rem",
                        color: "success.main",
                        textAlign: "center",
                      }}
                    >
                      Make sure Picture is clear and all Details on documents
                      are visible
                    </Typography>
                  </Box>
                ) : null}

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      minHeight: "13rem",
                      maxHeight: "13rem",
                      minWidth: "30rem",
                      maxWidth: "30rem",
                      border: "2px solid #e1e1e2",
                      borderRadius: "1.2rem",
                      overflow: "hidden",
                    }}
                  >
                    {ticket ? (
                      <img
                        src={URL.createObjectURL(ticket)} // Use the file URL
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    ) : (
                      <img
                        src={ticketimg}
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    )}
                  </Box>
                </Box>

                {ticket ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "4rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <UploadButton
                        setImage={setTicket}
                        label="Try Again"
                        varient="outlined"
                      />
                    </Box>

                    {btnloading ? (
                      <LoadingButton
                        sx={{
                          // mt: 3,
                          height: "3.75rem",
                          width: "16rem",
                          borderRadius: "0.625rem",
                          fontSize: "3rem",
                          "&.MuiLoadingButton-icon": {
                            fontSize: "3rem",
                          },
                        }}
                        color="secondary"
                        loading={btnloading}
                        variant="contained"
                      ></LoadingButton>
                    ) : (
                      <Button
                        endIcon={<ArrowForwardIcon />}
                        variant="contained"
                        type="submit"
                        onClick={() => setStep(5)}
                        sx={{
                          height: "3.75rem",
                          width: "16rem",
                          borderRadius: ".625rem",
                          px: "2rem",
                          textTransform: "capitalize",
                          fontWeight: 400,
                        }}
                      >
                        Confirm and submit
                      </Button>
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "4rem",
                    }}
                  >
                    {btnloading ? (
                      <LoadingButton
                        sx={{
                          // mt: 3,
                          height: "3.75rem",
                          width: "12rem",
                          borderRadius: "0.625rem",
                          fontSize: "3rem",
                          "&.MuiLoadingButton-icon": {
                            fontSize: "3rem",
                          },
                        }}
                        color="secondary"
                        loading={btnloading}
                        variant="contained"
                      ></LoadingButton>
                    ) : (
                      <Button
                        variant="outlined"
                        type="submit"
                        sx={{
                          textTransform: "capitalize",
                          borderRadius: ".625rem",
                          width: "12rem",
                        }}
                      >
                        Skip and Submit
                      </Button>
                    )}

                    <UploadButton
                      setImage={setTicket}
                      label="Upload From Storage"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Typography>No data</Typography>
          )}
        </Box>
      </form>
    </>
  );
};

DucumentUpload.propTypes = {
  label: PropTypes.string,
  setImage: PropTypes.any,
  varient: PropTypes.string,
};

export default DucumentUpload;
